import '../../../index.css';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import './TeacherDashboard.css';
import React from 'react';
import { Link } from 'react-router-dom';

const TeacherDashboard = () => {
  const userType = 'student';
  return (
    <div className="main-container">
      <HamburgerMenuTeacher />

      <section>
        <div className="admin-navbar"></div>
        <div className="classes">
          <Link
            to="/view-students-list"
            state={{ studentClass: 'IX' }}
            className="container"
          >
            <div className="img-container">
              <img src={require('../../../Assets/img/Frame.png')} alt="frame" />
            </div>
            <h5>Class IX</h5>
          </Link>
          <Link
            to="/view-students-list"
            state={{ studentClass: 'X' }}
            className="container-2"
          >
            <div className="img-container">
              <img
                src={require('../../../Assets/img/Frame 2.png')}
                alt="frame"
              />
            </div>
            <h5>Class X</h5>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default TeacherDashboard;
