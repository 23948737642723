// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSubject:
    JSON.parse(localStorage.getItem('selectedSubject')) || 'Mathematics',
  selectedWeekId: JSON.parse(localStorage.getItem('selectedWeekId')) || null,
  selectedChapterId:
    JSON.parse(localStorage.getItem('selectedChapterId')) || null,
  studentSelectedProblemId:
    JSON.parse(localStorage.getItem('studentSelectedMathAssignmentId')) || null,
  studentSelectedMathAssignmentId:
    JSON.parse(localStorage.getItem('studentSelectedMathAssignmentId')) || null,
  selectedProblemIds:
    JSON.parse(localStorage.getItem('selectedProblemIds')) || [],
  selectedAssignmentIds:
    JSON.parse(localStorage.getItem('selectedAssignmentIds')) || [],
  selectedStudentIds:
    JSON.parse(localStorage.getItem('selectedStudentIds')) || [],
  problems: JSON.parse(localStorage.getItem('problems')) || [],
  mathAssignments: JSON.parse(localStorage.getItem('mathAssignments')) || [],
};

const selectedSubjectSlice = createSlice({
  name: 'selectedSubject',
  initialState,
  reducers: {
    setSelectedSubject: (state, action) => {
      state.selectedSubject = action.payload;
      localStorage.setItem('selectedSubject', JSON.stringify(action.payload));
    },
    clearSelectedSubject: (state) => {
      state.selectedSubject = null;
      localStorage.removeItem('selectedSubject');
    },
  },
});
const selectedWeekIdSlice = createSlice({
  name: 'selectedWeekId',
  initialState,
  reducers: {
    setSelectedWeekId: (state, action) => {
      state.selectedWeekId = action.payload;
      localStorage.setItem('selectedWeekId', JSON.stringify(action.payload));
    },
    clearSelectedWeekId: (state) => {
      state.selectedWeekId = null;
      localStorage.removeItem('selectedWeekId');
    },
  },
});

const selectedChapterIdSlice = createSlice({
  name: 'selectedChapterId',
  initialState,
  reducers: {
    setSelectedChapterId: (state, action) => {
      state.selectedChapterId = action.payload;
      localStorage.setItem('selectedChapterId', JSON.stringify(action.payload));
    },
    clearSelectedChapterId: (state) => {
      state.selectedChapterId = null;
      localStorage.removeItem('selectedChapterId');
    },
  },
});

const studentSelectedProblemIdSlice = createSlice({
  name: 'studentSelectedProblemId',
  initialState,
  reducers: {
    setStudentSelectedProblemId: (state, action) => {
      state.studentSelectedProblemId = action.payload;
      localStorage.setItem(
        'studentSelectedProblemId',
        JSON.stringify(action.payload),
      );
    },
    clearStudentSelectedProblemId: (state) => {
      state.studentSelectedProblemId = null;
      localStorage.removeItem('studentSelectedProblemId');
    },
  },
});

const studentSelectedMathAssignmentIdSlice = createSlice({
  name: 'studentSelectedMathAssignmentId',
  initialState,
  reducers: {
    setStudentSelectedMathAssignmentId: (state, action) => {
      state.studentSelectedMathAssignmentId = action.payload;
      localStorage.setItem(
        'studentSelectedMathAssignmentId',
        JSON.stringify(action.payload),
      );
    },
    clearStudentSelectedMathAssignmentId: (state) => {
      state.studentSelectedMathAssignmentId = null;
      localStorage.removeItem('studentSelectedMathAssignmentId');
    },
  },
});

const selectedProblemIdsSlice = createSlice({
  name: 'selectedProblemIds',
  initialState,
  reducers: {
    setSelectedProblemIds: (state, action) => {
      state.selectedProblemIds = action.payload;
      localStorage.setItem(
        'selectedProblemIds',
        JSON.stringify(action.payload),
      );
    },
    clearSelectedProblemIds: (state) => {
      state.selectedProblemIds = [];
      localStorage.removeItem('selectedProblemIds');
    },
  },
});

const selectedAssignmentIdsSlice = createSlice({
  name: 'selectedAssignmentIds',
  initialState,
  reducers: {
    setSelectedAssignmentIds: (state, action) => {
      state.selectedAssignmentIds = action.payload;
      localStorage.setItem(
        'selectedAssignmentIds',
        JSON.stringify(action.payload),
      );
    },
    clearSelectedAssignmentIds: (state) => {
      state.selectedAssignmentIds = [];
      localStorage.removeItem('selectedAssignmentIds');
    },
  },
});

const selectedStudentIdsSlice = createSlice({
  name: 'selectedStudentIds',
  initialState,
  reducers: {
    setSelectedStudentIds: (state, action) => {
      state.selectedStudentIds = action.payload;
      localStorage.setItem(
        'selectedStudentIds',
        JSON.stringify(action.payload),
      );
    },
    clearSelectedStudentIds: (state) => {
      state.selectedStudentIds = null;
      localStorage.removeItem('selectedStudentIds');
    },
  },
});
const problemsSlice = createSlice({
  name: 'problems',
  initialState,
  reducers: {
    setProblems: (state, action) => {
      state.problems = action.payload;
      localStorage.setItem('problems', JSON.stringify(action.payload));
    },
    clearProblems: (state) => {
      state.problems = null;
      localStorage.removeItem('problems');
    },
  },
});
const mathAssignmentsSlice = createSlice({
  name: 'mathAssignments',
  initialState,
  reducers: {
    setMathAssignments: (state, action) => {
      state.mathAssignments = action.payload;
      localStorage.setItem('mathAssignments', JSON.stringify(action.payload));
    },
    clearMathAssignments: (state) => {
      state.mathAssignments = null;
      localStorage.removeItem('mathAssignments');
    },
  },
});

export const { setSelectedSubject, clearSelectedSubject } =
  selectedSubjectSlice.actions;

export const { setSelectedWeekId, clearSelectedWeekId } =
  selectedWeekIdSlice.actions;

export const { setSelectedChapterId, clearSelectedChapterId } =
  selectedChapterIdSlice.actions;

export const { setStudentSelectedProblemId, clearStudentSelectedProblemId } =
  studentSelectedProblemIdSlice.actions;

export const {
  setStudentSelectedMathAssignmentId,
  clearStudentSelectedMathAssignmentId,
} = studentSelectedMathAssignmentIdSlice.actions;

export const { setSelectedProblemIds, clearSelectedProblemIds } =
  selectedProblemIdsSlice.actions;

export const { setSelectedAssignmentIds, clearSelectedAssignmentIds } =
  selectedAssignmentIdsSlice.actions;

export const { setSelectedStudentIds, clearSelectedStudentIds } =
  selectedStudentIdsSlice.actions;

export const { setProblems, clearProblems } = problemsSlice.actions;

export const { setMathAssignments, clearMathAssignments } =
  mathAssignmentsSlice.actions;

export const reducers = {
  selectedSubject: selectedSubjectSlice.reducer,
  selectedWeekId: selectedWeekIdSlice.reducer,
  selectedChapterId: selectedChapterIdSlice.reducer,
  studentSelectedProblemId: studentSelectedProblemIdSlice.reducer,
  studentSelectedMathAssignmentId: studentSelectedMathAssignmentIdSlice.reducer,
  selectedProblemIds: selectedProblemIdsSlice.reducer,
  selectedAssignmentIds: selectedAssignmentIdsSlice.reducer,
  selectedStudentIds: selectedStudentIdsSlice.reducer,
  problems: problemsSlice.reducer,
  mathAssignments: mathAssignmentsSlice.reducer,
};
