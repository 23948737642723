import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../utils/Button/Button'; // Import the Button component
import './Login.css'; // Import CSS for styling
import '../../index.css'

const Login = () => {

    const navigate = useNavigate();

    const redirectTo = (path) => {
        navigate(path);
    };
    return (
        <div className="main-background">
            <div className="inner-card">
                <div className="login-container">
                    <h2>Login as</h2>
                    <Button text="Organisation" className="login-button" onClick={() => redirectTo('/login/organisation')} />
                    <Button text="Teacher" className="login-button" onClick={() => redirectTo('/login/teacher')} />
                    <Button text="Student" className="login-button" onClick={() => redirectTo('/login/student')} />
                </div>
            </div>
        </div>
    );
};

export default Login;
