import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import {
  faBars,
  faAnglesLeft,
  faBookOpen,
  faUser,
  faTableList,
  faCheckDouble,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

import './HamburgerMenuTeacher.css'; // make sure to create this CSS file

import { Button, Menu } from 'antd';

const HamburgerMenuTeacher = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const userType = 'student';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickUploadCSV = () => {
    navigate('/upload-csv');
  };

  const handleAssignAssignments = () => {
    navigate('/create-assignment');
  };
  const handleViewAssignments = () => {
    navigate('/teacher-dashboard');
  };

  return (
    <div>
      {isOpen ? (
        <section className="left-section-small">
          <div
            style={{ visibility: isOpen ? 'visible' : 'hidden' }}
            className="hamburger"
            onClick={toggleMenu}
          >
            <FontAwesomeIcon className="hamburger-icon" icon={faBars} />
          </div>
          <div className="admin-actions-small">
            <Menu defaultOpenKeys={['sub1']} mode="inline">
              <Menu.SubMenu
                key="sub1"
                icon={<FontAwesomeIcon icon={faBookOpen} />}
              >
                <Menu.Item
                  onClick={handleViewAssignments}
                  icon={<FontAwesomeIcon icon={faTableList} />}
                ></Menu.Item>
                <Menu.Item
                  onClick={handleAssignAssignments}
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                ></Menu.Item>
              </Menu.SubMenu>
              <Menu.Item
                onClick={() => navigate('/upload-csv')}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></Menu.Item>
              <Menu.Item
                onClick={() => navigate('/my-account')}
                icon={<FontAwesomeIcon icon={faUser} />}
              ></Menu.Item>
            </Menu>
          </div>

          <div className="admin-help"></div>
        </section>
      ) : (
        <section className="left-section">
          <div className="assignment-and-menu">
            <FontAwesomeIcon
              className="hamburger-icon"
              onClick={toggleMenu}
              icon={faAnglesLeft}
            />
          </div>
          <div className="admin-actions">
            <Menu defaultOpenKeys={['sub1']} mode="inline">
              <Menu.SubMenu
                icon={<FontAwesomeIcon icon={faBookOpen} />}
                title="All Assignments"
                key="sub1"
              >
                <Menu.Item
                  onClick={handleViewAssignments}
                  icon={<FontAwesomeIcon icon={faTableList} />}
                >
                  View Classes
                </Menu.Item>
                <Menu.Item
                  onClick={handleAssignAssignments}
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                >
                  Assign Assignments
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item
                onClick={handleClickUploadCSV}
                icon={<FontAwesomeIcon icon={faUpload} />}
              >
                Upload CSV
              </Menu.Item>
              <Menu.Item
                onClick={() => navigate('/my-account')}
                icon={<FontAwesomeIcon icon={faUser} />}
              >
                My Account
              </Menu.Item>
            </Menu>
          </div>

          <div className="admin-help">
            <div>
              <img
                src={require('../../../Assets/img/headset 1.png')}
                alt="headset"
              />
              <h5>Contact Admin</h5>
            </div>
            <div>
              <img
                src={require('../../../Assets/img/interrogation 1.png')}
                alt="interrogation"
              />
              <h5>How To Add Course</h5>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default HamburgerMenuTeacher;
