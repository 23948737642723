import React, { useState, useEffect } from 'react';
import { Modal, Radio, Table, Tag, Space } from 'antd';
import { config } from '../../../config';
import './AssignmentTable.css';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  clearSelectedAssignmentIds,
  setSelectedAssignmentIds,
} from '../../../redux/slices/selectionSlice';
import { EyeOutlined } from '@ant-design/icons';

const AssignmentTable = ({ chapterId }) => {
  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      render: (_, record, index) => index + 1,
    },
    {
      title: 'Assignment Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <p style={{ fontWeight: 600 }}>{text}</p>,
    },
    {
      title: 'Marks',
      dataIndex: 'marks',
      key: 'name',
    },
    {
      title: 'Last Date',
      dataIndex: 'lastDate',
      key: 'name',
    },
    {
      title: 'Preview',
      dataIndex: '',
      key: 'preview',
      render: (_, record) => (
        <EyeOutlined
          className="eye-icon-ant"
          style={{ fontSize: '125%' }}
          onClick={() => handlePreviewClick(record)}
        />
      ),
    },
  ];

  const [mathAssignment, setMathAssignment] = useState();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [value, setValue] = useState(1);

  const showPreviewModal = () => {
    setIsPreviewModalVisible(true);
  };

  // Handler to close Modal
  const handlePreviewOk = () => {
    setMathAssignment();
    setValue(1);
    setIsPreviewModalVisible(false);
  };

  const handlePreviewCancel = () => {
    setMathAssignment();
    setValue(1);
    setIsPreviewModalVisible(false);
  };

  async function handlePreviewClick(record) {
    setMathAssignment();

    console.log('Preview clicked for record:', record);
    const previewUrl = 'teacher/math/get-questions';
    const endPoint = config.API_URL + previewUrl;

    try {
      const response = await axios.post(
        endPoint,
        {
          assignmentIds: [record._id],
        },
        { headers: { Authorization: token } },
      );
      console.log('🚀 ~ handlePreviewClick ~ response:', response);
      setMathAssignment(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsPreviewModalVisible(true);
    }
  }

  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  const [assignments, setAssignments] = useState([]);
  const token = useSelector((state) => state.token.token);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getMathAssignmentList() {
      setLoading(true);
      const assignmentListUrl = 'teacher/math/get-assignments';
      const endPoint = config.API_URL + assignmentListUrl + '/' + chapterId;
      const response = await axios.get(endPoint, {
        headers: { Authorization: token },
      });
      console.log(response);
      setAssignments(
        response.data.data.map((assignment, index) => ({
          ...assignment,
          key: index, // Ensure each problem has a unique key for selection
        })),
      );
      setLoading(false);
    }
    getMathAssignmentList();

    // To empty the problem list
    const emptyAssignmentList = () => {
      dispatch(clearSelectedAssignmentIds());
    };
    emptyAssignmentList();
  }, [token, chapterId, dispatch]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Map selected row keys to their _id
    const selectedIds = newSelectedRowKeys
      .map((key) => assignments.find((problem) => problem.key === key)?._id)
      .filter((id) => id !== undefined);
    dispatch(setSelectedAssignmentIds(selectedIds)); // Dispatch the setSelectedWeekId action with the selected weekId

    console.log('Selected assignment IDs:', selectedIds);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
  };

  const onChangeOptions = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={assignments}
        loading={loading}
      />
      {console.log('Math assignments after setting new data:', mathAssignment)}{' '}
      <Modal
        className="preview-modal"
        title="Preview"
        open={isPreviewModalVisible}
        onOk={handlePreviewOk}
        onCancel={handlePreviewCancel}
      >
        {mathAssignment?.map((assignment) => (
          <div key={assignment._id}>
            <h2 style={{ fontWeight: 600, fontSize: '16px' }}>
              {assignment.name}
            </h2>
            <br />
            {assignment.questions?.map((question) => (
              <div key={question._id}>
                <h2 style={{ fontWeight: 600 }}>{question.question}</h2>
                {question.options?.map((option, index) => (
                  <Radio.Group
                    key={question._id + index}
                    onChange={onChangeOptions}
                    value={question.correctOption}
                  >
                    <Space direction="vertical">
                      <Radio disabled={true} value={option}>
                        {option}
                      </Radio>
                    </Space>
                  </Radio.Group>
                ))}
              </div>
            ))}
            <br />
          </div>
        ))}
      </Modal>
    </>
  );
};
export default AssignmentTable;
