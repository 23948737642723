import React from 'react';
import './StudentDashboard.css';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu';
import CurrentAssignment from './CurrentAssignment';
import Problems from './Problems';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import { useAppContext } from '../../../AppContext';
import { useDispatch } from 'react-redux';
import { setSelectedWeekId } from '../../../redux/slices/selectionSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import SubjectsTable from './SubjectsTable';

const Subjects = () => {
  return (
    <div className="main-container">
      <HamburgerMenuStudent />

      <section>
        <SubjectsTable />
      </section>
    </div>
  );
};

export default Subjects;
