import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import { config } from '../../../config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProblems,
  setStudentSelectedProblemId,
} from '../../../redux/slices/selectionSlice';

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Problem Name',
    dataIndex: 'question',
    key: 'name',
    render: (text) => (
      <Link to="/compiler" style={{ fontWeight: 600 }}>
        {text}
      </Link>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'name',
  },
  {
    title: 'Marks',
    dataIndex: 'marks',
    key: 'name',
  },
  {
    title: 'Obtained Marks',
    dataIndex: 'obtainedMarks',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'name',
    render: (text) => (
      <p
        style={{
          fontWeight: 600,
          color:
            text === 'completed' ? 'green' : text === 'pending' ? 'red' : '',
        }}
      >
        {text}
      </p>
    ),
  },
  {
    title: 'Last Date',
    dataIndex: 'lastDate',
    key: 'name',
  },
];

const Problems = ({ filterStatus, weekId }) => {
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  const problems = useSelector((state) => state.problems.problems);
  const token = useSelector((state) => state.token.token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getProblemsList() {
      setLoading(true);
      const weekListUrl = 'student/assignments';
      const endPoint = config.API_URL + weekListUrl + '/' + weekId;
      const response = await axios.get(endPoint, {
        headers: { Authorization: token },
      });
      console.log(response);
      const filteredProblems =
        filterStatus === 'all'
          ? response.data.data
          : response.data.data.filter(
              (problem) => problem.status === filterStatus,
            );
      dispatch(
        setProblems(
          filteredProblems.map((problem) => ({ ...problem, key: problem._id })),
        ),
      );

      setLoading(false);
    }
    getProblemsList();
  }, [token, weekId, dispatch, filterStatus]);

  const handleRowClick = (record) => {
    // Assuming record.key contains week._id
    console.log('Clicked record:', record.key);
    dispatch(setStudentSelectedProblemId(record.key));
  };

  return (
    <Table
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
      columns={columns}
      dataSource={problems}
      loading={loading}
    />
  );
};
export default Problems;
