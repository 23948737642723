import React from 'react';
import './ViewReportCard.css';
import { useSelector } from 'react-redux';
import HamburgerMenuStudent from '../User/Student/HamburgerMenuStudent';
import HamburgerMenuTeacher from '../User/Teacher/HamburgerMenuTeacher';

const ViewReportCard = () => {
  const userType = useSelector((state) => state.user.userType);
  return (
    <div className="main-container">
      {userType === 'Student' && <HamburgerMenuStudent />}
      {userType === 'Teacher' && <HamburgerMenuTeacher />}
      <section>
        <div className="view-report-div">
          <img
            className="report-card-image"
            src={require('../../Assets/img/report-card.jpg')}
            alt="report-card"
          />
        </div>
      </section>
    </div>
  );
};

export default ViewReportCard;
