import React, { useState } from 'react';
import WeeksTable from './WeeksTable';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import {
  setSelectedChapterId,
  setSelectedSubject,
  setSelectedWeekId,
} from '../../../redux/slices/selectionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import MathChapterTable from './MathChapterTable';

const CreateAssignment = () => {
  const selectedSubject = useSelector(
    (state) => state.selectedSubject.selectedSubject,
  );
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  // Function to handle week selection
  const handleWeekSelect = (weekId) => {
    dispatch(setSelectedWeekId(weekId)); // Dispatch the setSelectedWeekId action with the selected weekId
  };
  // Function to handle week selection
  const handleChapterSelect = (chapterId) => {
    dispatch(setSelectedChapterId(chapterId)); // Dispatch the setSelectedWeekId action with the selected weekId
  };

  const handleChangeSubject = (value) => {
    console.log('🚀 ~ handleChangeSubject ~ value:', value);
    dispatch(setSelectedSubject(value));
  };

  return (
    <div className="main-container">
      <HamburgerMenuTeacher />
      <section>
        <Select
          mode="single"
          placeholder="Select Subject"
          defaultValue="Computer Science"
          onChange={handleChangeSubject}
          options={[
            {
              options: [
                { label: 'Computer Science', value: 'Computer Science' },
                { label: 'Mathematics', value: 'Mathematics' },
              ],
            },
          ]}
        />

        {selectedSubject === 'Mathematics' ? (
          <MathChapterTable onChapterSelect={handleChapterSelect} />
        ) : (
          <WeeksTable onWeekSelect={handleWeekSelect} />
        )}
      </section>
    </div>
  );
};

export default CreateAssignment;
