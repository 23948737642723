import React from 'react';
import { Select } from 'antd';
import './UploadCsv.css';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';

const UploadCsv = () => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  // Function to trigger file input click
  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Process the file here (e.g., read it or upload it to a server)
    console.log(file);
  };

  return (
    <div className="csv-main-container">
      <HamburgerMenuTeacher />
      <section className="csv-section">
        <div className="upload-container">
          <div className="roster-heading">
            <h1 style={{ fontWeight: 600 }}>Type Roster</h1>
            <Select
              defaultValue="Select Type Roster"
              style={{ width: 200 }}
              onChange={handleChange}
              options={[
                {
                  options: [
                    { label: 'Class IX', value: 'Class IX' },
                    { label: 'Class X', value: 'Class X' },
                  ],
                },
              ]}
            />
          </div>
          <div className="upload-csv">
            <h1 style={{ fontWeight: 600 }}>Upload Roster</h1>
            <div className="outside-div">
              <div className="inner-div" onClick={handleUploadClick}>
                {/* Upload CSV Folder Content */}
                {/* Invisible file input */}
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Accept CSV and Excel files
                />
                <div className="upload-csv-folder">
                  <img
                    className="upload-folder"
                    src={require('../../../Assets/img/folder.png')}
                    alt=""
                  />
                  <h1 className="select-csv-header">
                    Select CSV and XLXS file only.
                  </h1>
                  <p className="file-include-paragraph">
                    (That file include student first name, last name, email
                    address, roll number)
                  </p>
                </div>
              </div>
            </div>
            <button className="save-and-next">Save & Next</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadCsv;
