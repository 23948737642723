import './ContactUs.css';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react'; // Replace with the correct path to your CSS file

function BookADemo() {
  const [formData, setFormData] = useState({
    name: '',
    lname: '',
    email: '',
    number: '',
    org: '',
  });
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = async () => {
    try {
      const response = await fetch(
        'https://nodify-api.onrender.com/api/users',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        },
      );

      if (response.ok) {
        setShowSuccessPopup(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(`Oops! ${errorData.msg}`);
        setShowErrorPopup(true);
      }
    } catch (error) {
      setErrorMessage('Oops! Internal server error');
      setShowErrorPopup(true);
    }
  };

  const closePopup = () => {
    setShowSuccessPopup(false);
    setShowErrorPopup(false);
    if (showSuccessPopup) {
      navigate('/'); // Redirect to home page only if it's a success popup
    }
  };

  return (
    <div className="main-background">
      <div className="inner-card">
        <div className="contact-form-card">
          <h2 className="book-a-demo-heading">Book a Demo</h2>
          <form id="demoForm">
            <div className="input-group">
              <input
                type="text"
                id="name"
                name="name"
                required
                onChange={handleChange}
              />
              <label htmlFor="name">Name</label>
            </div>
            <div className="input-group">
              <input
                type="text"
                id="lname"
                name="lname"
                required
                onChange={handleChange}
              />
              <label htmlFor="lname">Last Name</label>
            </div>
            <div className="input-group">
              <input
                type="email"
                id="email"
                name="email"
                required
                onChange={handleChange}
              />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input-group">
              <input
                type="tel"
                id="number"
                name="number"
                required
                onChange={handleChange}
              />
              <label htmlFor="number">Mobile number</label>
            </div>
            <div className="input-group">
              <input
                type="text"
                id="org"
                name="org"
                required
                onChange={handleChange}
              />
              <label htmlFor="org">Organization</label>
            </div>
            <div className="submit-button">
              <button
                className="submit-button-button"
                type="button"
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <div className="popup-content">
            <p>Form submitted successfully!</p>
            <button onClick={closePopup}>OK</button>
          </div>
        </div>
      )}

      {showErrorPopup && (
        <div className="error-popup">
          <div className="popup-content">
            <p>{errorMessage}</p>
            <button onClick={closePopup}>Try again!</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookADemo;
