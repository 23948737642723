import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../../../config';
import StudentTable from './StudentTable';
import './StudentList.css';
import { useSelector } from 'react-redux';
import { Modal, Input, Radio } from 'antd';
import { Select } from 'antd';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import { DatePicker, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
const { RangePicker } = DatePicker;

const StudentList = () => {
  const token = useSelector((state) => state.token.token);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
  const [assignDate, setAssignDate] = useState();
  const [problems, setProblems] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [studentClass, setStudentClass] = useState('IX');
  const [value, setValue] = useState(1);

  const selectedSubject = useSelector(
    (state) => state.selectedSubject.selectedSubject,
  );
  const selectedStudentIds = useSelector(
    (state) => state.selectedStudentIds.selectedStudentIds,
  );
  const selectedProblemIds = useSelector(
    (state) => state.selectedProblemIds.selectedProblemIds,
  );

  const selectedAssignmentIds = useSelector(
    (state) => state.selectedAssignmentIds.selectedAssignmentIds,
  );
  console.log('selectedProblemsIds', selectedProblemIds);
  console.log('selectedAssignmentIds', selectedAssignmentIds);
  const navigate = useNavigate();

  const handleChangeSection = (value) => {
    console.log(`selected ${value}`);
  };

  const handleChangeClass = (value) => {
    console.log('🚀 ~ handleChangeClass ~ value:', value);
    const val = value.split(' ')[1];
    console.log('🚀 ~ handleChangeClass ~ val:', val);
    setStudentClass(val);
  };

  const onChangeOptions = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setAssignDate(dateString);
  };
  const onOk = (value) => {
    // this onOK for date element
    console.log('onOk: ', value);
    // teacher-publish url = 'teacher/publish'
  };

  // Handler to show Modal
  const showPreviewModal = () => {
    setIsPreviewModalVisible(true);
  };

  // Handler to close Modal
  const handlePreviewOk = () => {
    setIsPreviewModalVisible(false);
  };

  const handlePreviewCancel = () => {
    setIsPreviewModalVisible(false);
  };
  // Handler to show Modal
  const showPublishModal = () => {
    setIsPublishModalVisible(true);
  };

  // Handler to close Modal
  const handlePublishOk = async () => {
    setIsPublishModalVisible(false);

    // for ComputerScience subject
    const publishComputerScience = async () => {
      const publishUrl = 'teacher/publish';
      const endPoint = config.API_URL + publishUrl;

      try {
        const response = await axios.post(
          endPoint,
          {
            studentIds: selectedStudentIds,
            problemIds: selectedProblemIds,
            date: assignDate,
          },
          { headers: { Authorization: token } },
        );
        if (response.data.success) {
          navigate('/teacher-dashboard');
          toast.success('Assignment Published Successfully');
        }
      } catch (error) {
        console.log(error);
        toast.error('Assignment could not be published');
      }
    };

    // for Mathematics subject
    const publishMathematics = async () => {
      const publishUrl = 'teacher/math/publish';
      const endPoint = config.API_URL + publishUrl;

      try {
        const response = await axios.post(
          endPoint,
          {
            studentIds: selectedStudentIds,
            assignmentIds: selectedAssignmentIds,
            // date: assignDate,
          },
          { headers: { Authorization: token } },
        );
        if (response.data.success) {
          navigate('/teacher-dashboard');
          toast.success('Mathematics Assignment Published Successfully');
        }
      } catch (error) {
        console.log(error);
        toast.error('Mathematics Assignment could not be published');
      }
    };

    // conditionally call function based on subject selected
    selectedSubject === 'Mathematics'
      ? publishMathematics()
      : publishComputerScience();
  };

  const handlePublishCancel = () => {
    setIsPublishModalVisible(false);
  };

  useEffect(() => {
    const handlePreviewProblems = async () => {
      const previewUrl = 'teacher/preview';
      const endPoint = config.API_URL + previewUrl;

      try {
        const response = await axios.post(
          endPoint,
          {
            problems: selectedProblemIds,
          },
          { headers: { Authorization: token } },
        );
        setProblems(response.data.data);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    // For Assignments preview
    const handlePreviewAssignments = async () => {
      const previewUrl = 'teacher/math/get-questions';
      const endPoint = config.API_URL + previewUrl;

      try {
        const response = await axios.post(
          endPoint,
          {
            assignmentIds: selectedAssignmentIds,
          },
          { headers: { Authorization: token } },
        );
        console.log('🚀 ~ handlePreviewAssignments ~ response:', response);
        setAssignments(response.data.data);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    selectedSubject === 'Mathematics'
      ? handlePreviewAssignments()
      : handlePreviewProblems();
  }, [selectedProblemIds, selectedAssignmentIds, token, selectedSubject]);

  return (
    <div className="main-container">
      <HamburgerMenuTeacher />
      <section>
        <Select
          mode="multiple"
          placeholder="Select Sections"
          // defaultValue="Section A"
          onChange={handleChangeSection}
          options={[
            {
              options: [
                { label: 'Section A', value: 'Section A' },
                { label: 'Section B', value: 'Section B' },
                { label: 'Section C', value: 'Section C' },
                { label: 'Section D', value: 'Section D' },
              ],
            },
          ]}
        />
        <Select
          mode="single"
          placeholder="Select Class"
          defaultValue="Class IX"
          onChange={handleChangeClass}
          options={[
            {
              options: [
                { label: 'Class IX', value: 'Class IX' },
                { label: 'Class X', value: 'Class X' },
              ],
            },
          ]}
        />
        <StudentTable studentClass={studentClass} />
        <div className="publish-div">
          <button onClick={showPreviewModal} className="preview-button">
            Preview
          </button>
          <button onClick={showPublishModal} className="publish-button">
            Publish
          </button>
          <Modal
            className="preview-modal"
            title="Preview"
            open={isPreviewModalVisible}
            onOk={handlePreviewOk}
            onCancel={handlePreviewCancel}
          >
            {problems?.map((problem) => (
              <div className="problem-container">
                <h2 className="problem-name" style={{ fontWeight: 600 }}>
                  {problem.question}
                </h2>
                <br />
                <p className="problem-description">{problem.description}</p>
                <br />
                {problem.testcases.map((testcase, index) => (
                  <div className="problem-examples">
                    <h2>{`Example ${index + 1}`}</h2>
                    <br />
                    <h4>
                      <b style={{ fontWeight: 600 }}>Input: </b>
                      {testcase.input.split('\\n').map((num) => (
                        <p>{num}</p>
                      ))}
                    </h4>

                    <h4>
                      <b style={{ fontWeight: 600 }}>Output: </b>
                      {`${testcase.output}`}
                    </h4>

                    <br />
                  </div>
                ))}
              </div>
            ))}

            {assignments?.map((assignment) => (
              <div>
                <h2 style={{ fontWeight: 600, fontSize: '16px' }}>
                  {assignment.name}
                </h2>
                <br />
                {assignment.questions?.map((question) => (
                  <div>
                    <h2 style={{ fontWeight: 600 }}>{question.question}</h2>
                    {question.options?.map((option) => (
                      <Radio.Group
                        key={question._id}
                        onChange={onChangeOptions}
                        value={question.correctOption}
                      >
                        <Space direction="vertical">
                          <Radio disabled={true} value={option}>
                            {option}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    ))}
                  </div>
                ))}
                <br />
              </div>
            ))}

            {selectedSubject === 'Computer Science' && (
              <div className="student-list-video-div">
                <iframe
                  className="video-iframe"
                  width="470"
                  height="300"
                  src="https://www.youtube.com/embed/vbQBQrmwt4s?si=WRq6NEUM6IM8G3VU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </Modal>
          <Modal
            title="Publish"
            open={isPublishModalVisible}
            onOk={handlePublishOk}
            onCancel={handlePublishCancel}
          >
            <Space direction="vertical" size={12}>
              {/* <DatePicker showTime onChange={onChange} onOk={onOk} /> */}
              <RangePicker
                showTime={{
                  format: 'hh:mm a',
                }}
                format="YYYY-MM-DD hh:mm a"
                onChange={onChange}
                onOk={onOk}
              />
            </Space>
          </Modal>
        </div>
      </section>
    </div>
  );
};

export default StudentList;
