import React from 'react';
import './Footer.css'; // Ensure this is the correct path to your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className="footer-section" id="footer">
      <div className="overlap-2">
        <p className="element-kaizen-privacy">© 2023 Nodify | Privacy Policy</p>
        <div className="social-media-icons-div">
          <FontAwesomeIcon className="social-media-icons" icon={faFacebook} />
          <FontAwesomeIcon className="social-media-icons" icon={faXTwitter} />
          <FontAwesomeIcon className="social-media-icons" icon={faInstagram} />
          <FontAwesomeIcon className="social-media-icons" icon={faLinkedin} />
        </div>
        {/* <img
          className="social-media"
          src="https://c.animaapp.com/TEOYoSB0/img/social-media@2x.png"
          alt="Social Media Icons"
        /> */}
      </div>
    </div>
  );
}

export default Footer;
