import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  clearSelectedProblemIds,
  setSelectedProblemIds,
} from '../../../redux/slices/selectionSlice';

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Problem Name',
    dataIndex: 'question',
    key: 'name',
    render: (text) => <p style={{ fontWeight: 600 }}>{text}</p>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'name',
  },
];

const ProblemsTable = ({ weekId }) => {
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  const [problems, setProblems] = useState([]);
  const token = useSelector((state) => state.token.token);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getProblemsList() {
      setLoading(true);
      const weekListUrl = 'assignment/get-week-assignments';
      const endPoint = config.API_URL + weekListUrl + '/' + weekId;
      const response = await axios.get(endPoint, {
        headers: { Authorization: token },
      });
      console.log(response);
      setProblems(
        response.data.data.assignments.map((problem, index) => ({
          ...problem,
          key: index, // Ensure each problem has a unique key for selection
        })),
      );
      setLoading(false);
    }
    getProblemsList();

    // To empty the problem list
    const emptyProblemsList = () => {
      dispatch(clearSelectedProblemIds());
    };
    emptyProblemsList();
  }, [token, weekId, dispatch]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Map selected row keys to their _id
    const selectedIds = newSelectedRowKeys
      .map((key) => problems.find((problem) => problem.key === key)?._id)
      .filter((id) => id !== undefined);
    dispatch(setSelectedProblemIds(selectedIds)); // Dispatch the setSelectedWeekId action with the selected weekId

    console.log('Selected problem IDs:', selectedIds);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
  };

  return (
    <Table
      columns={columns}
      rowSelection={rowSelection}
      dataSource={problems}
      loading={loading}
    />
  );
};
export default ProblemsTable;
