import React from 'react';
import './Button.css'; // Import CSS for styling

const Button = ({ text, onClick, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`custom-button ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
