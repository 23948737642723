import React from 'react';
import HamburgerMenuStudent from '../User/Student/HamburgerMenuStudent';
import './HelpMe.css';

const HelpMe = () => {
  return (
    <div className="main-container">
      <HamburgerMenuStudent />
      <section className="help-me-section">
        <div className="help-me-div">
          <form action="">
            <label style={{ fontWeight: 600 }} htmlFor="help">
              Describe your concern :
            </label>
            <input autoComplete="false" name="help" required type="text" />
            <div className="submit-div">
              <button
                onClick={(e) => e.preventDefault()}
                className="help-me-submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HelpMe;
