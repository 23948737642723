import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Chapter Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => (
      <Link to="/math-assignments" style={{ fontWeight: 600 }}>
        {text}
      </Link>
    ),
  },
  {
    title: 'Marks',
    dataIndex: 'marks',
    key: 'name',
  },
];

const MathChapterTable = ({ onChapterSelect }) => {
  const [chapters, setChapters] = useState([]);
  const token = useSelector((state) => state.token.token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getChapterList() {
      setLoading(true);
      const chapterListUrl = 'teacher/math/get-chapters';
      const endPoint = config.API_URL + chapterListUrl;
      try {
        const response = await axios.get(endPoint, {
          headers: { Authorization: token },
        });
        console.log(response);
        const chaptersData = response.data.data.map((chapter, index) => ({
          ...chapter,
          key: chapter._id, // Assuming 'id' is the unique ID field in your response data
          index: index + 1, // Add index if you need to keep the No. column accurate after operations like sorting
        }));
        setChapters(chaptersData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getChapterList();
  }, [token]);

  const handleRowClick = (record) => {
    // Assuming record.key contains week._id
    console.log('Clicked record:', record.key);
    onChapterSelect(record.key);
  };

  return (
    <Table
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
      columns={columns}
      dataSource={chapters}
      loading={loading}
    />
  );
};
export default MathChapterTable;
