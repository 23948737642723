import React, { useState } from 'react';

import {
  setSelectedChapterId,
  setSelectedSubject,
  setSelectedWeekId,
} from '../../../redux/slices/selectionSlice';
import { useDispatch, useSelector } from 'react-redux';
import MathChapterTableStudent from './MathChapterTableStudent';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import { useLocation } from 'react-router-dom';

const MathChapterListStudent = () => {
  const selectedSubject = useSelector(
    (state) => state.selectedSubject.selectedSubject,
  );
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  // Function to handle chapter selection
  const handleChapterSelect = (chapterId) => {
    dispatch(setSelectedChapterId(chapterId)); // Dispatch the setSelectedChapterId action with the selected chapterId
  };

  const location = useLocation();
  const { filterStatus } = location.state || { filterStatus: 'all' };
  console.log('🚀 ~ MathChapterListStudent ~ filterStatus:', filterStatus);

  return (
    <div className="main-container">
      <HamburgerMenuStudent />
      <section>
        <MathChapterTableStudent
          filterStatus={filterStatus}
          onChapterSelect={handleChapterSelect}
        />
      </section>
    </div>
  );
};

export default MathChapterListStudent;
