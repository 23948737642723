import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import { config } from '../../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';

let newFilterStatus = '';

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Assignment Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => (
      <Link
        to="/problemspage"
        state={{ filterStatus: newFilterStatus }}
        style={{ fontWeight: 600 }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: 'Marks',
    dataIndex: 'marks',
    key: 'name',
  },
];
const CurrentAssignment = ({ filterStatus, onWeekSelect }) => {
  newFilterStatus = filterStatus;
  const [weeks, setWeeks] = useState([]);
  //   const { token } = useAppContext();
  const token = useSelector((state) => state.token.token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // get topic list
    async function getTopicList() {
      setLoading(true);
      const weekListUrl = 'student/get-topic';
      const endPoint = config.API_URL + weekListUrl;

      try {
        const response = await axios.get(endPoint, {
          headers: { Authorization: token },
        });
        console.log(response);
        //   setWeeks(response.data.data);
        const weeksData = response.data.data.map((week, index) => ({
          ...week,
          key: week._id, // Assuming 'id' is the unique ID field in your response data
          index: index + 1, // Add index if you need to keep the No. column accurate after operations like sorting
        }));
        setWeeks(weeksData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getTopicList();
  }, [token]);

  // to handle row click
  const handleRowClick = (record) => {
    // Assuming record.key contains week._id
    console.log('Clicked record:', record.key);
    onWeekSelect(record.key);
  };

  return (
    <Table
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
      columns={columns}
      dataSource={weeks}
      loading={loading}
    />
  );
};
export default CurrentAssignment;
