import React from 'react';
import './StudentDashboard.css';
import CurrentAssignment from './CurrentAssignment';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import { useDispatch } from 'react-redux';
import { setSelectedWeekId } from '../../../redux/slices/selectionSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const StudentDashboard = () => {
  const userType = 'student';
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  // Function to handle week selection
  const handleWeekSelect = (weekId) => {
    dispatch(setSelectedWeekId(weekId)); // Dispatch the setSelectedWeekId action with the selected weekId
  };

  const location = useLocation();
  const { filterStatus } = location.state || { filterStatus: 'all' };
  console.log('🚀 ~ StudentDashboard ~ filterStatus:', filterStatus);

  return (
    <div className="main-container">
      <HamburgerMenuStudent />

      <section>
        <CurrentAssignment
          filterStatus={filterStatus}
          onWeekSelect={handleWeekSelect}
        />
      </section>
    </div>
  );
};

export default StudentDashboard;
