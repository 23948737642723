import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setStudentSelectedMathAssignmentId } from '../../../redux/slices/selectionSlice';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import MathAssignmentTableStudent from './MathAssignmentTableStudent';

const MathAssignmentListStudent = () => {
  const dispatch = useDispatch();
  const selectedChapterId = useSelector(
    (state) => state.selectedChapterId.selectedChapterId,
  );

  const handleAssignmentSelect = (assignmentId) => {
    dispatch(setStudentSelectedMathAssignmentId(assignmentId)); // Dispatch the setSelectedWeekId action with the selected weekId
  };

  console.log('Received chapterId in AssignmentList:', selectedChapterId);
  const location = useLocation();
  const { filterStatus } = location.state;
  console.log('🚀 ~ MathAssignmentListStudent ~ filterStatus:', filterStatus);

  return (
    <div className="main-container">
      <HamburgerMenuStudent />
      <section>
        <MathAssignmentTableStudent
          onAssignmentSelect={handleAssignmentSelect}
          filterStatus={filterStatus}
          chapterId={selectedChapterId}
        />
      </section>
    </div>
  );
};

export default MathAssignmentListStudent;
