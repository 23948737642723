import React, { useEffect, useState } from 'react';
import ProblemsTable from './ProblemsTable';
import './ProblemsList.css';
import { useNavigate } from 'react-router-dom';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const ProblemsList = () => {
  const selectedWeekId = useSelector(
    (state) => state.selectedWeekId.selectedWeekId,
  );
  const selectedProblemIds = useSelector(
    (state) => state.selectedProblemIds.selectedProblemIds,
  );
  console.log('Received weekId in ProblemsList:', selectedWeekId);
  console.log('Received problemId in ProblemsList:', selectedProblemIds);
  const [disabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  function handleAssignButtonClick() {
    navigate('/student-list');
  }
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(selectedProblemIds);
    setIsDisabled(true);
    if (selectedProblemIds.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedProblemIds]);

  return (
    <div className="main-container">
      <HamburgerMenuTeacher />
      <section>
        <ProblemsTable weekId={selectedWeekId} />
        <div className="assign-div">
          <button
            disabled={disabled}
            className={disabled ? 'disable-button' : 'assign-button'}
            onClick={handleAssignButtonClick}
            // className="assign-button"
          >
            Assign to Students
          </button>
        </div>
      </section>
    </div>
  );
};

export default ProblemsList;
