// Compiler.js
import React, { useState } from 'react';
import AceEditor from 'react-ace';

// Import Ace Editor themes and modes
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-python';
// ... import other modes you need ...

import './Compiler.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { config } from '../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Modal } from 'antd';

function Compiler({ sphereId }) {
  const [source, setSource] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [input, setInput] = useState('');

  // for handling run
  const [output, setOutput] = useState('');
  const [runCompilerInfo, setRunCompilerInfo] = useState('');
  const [runErrorInfo, setRunErrorInfo] = useState('');

  // for handling submit
  const [error, setError] = useState('');
  const [testcases, setTestcases] = useState();
  const [debuginfo, setDebuginfo] = useState('');
  const [compilerInfo, setCompilerInfo] = useState('');
  const [language, setLanguage] = useState('python');
  const [theme, setTheme] = useState('github');
  const studentSelectedProblemId = useSelector(
    (state) => state.studentSelectedProblemId.studentSelectedProblemId,
  );
  const token = useSelector((state) => state.token.token);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  function onChange(newValue) {
    setSource(newValue);
  }

  function handleInputChange(event) {
    setInput(event.target.value);
  }

  async function handleRun(event) {
    if (source.length === 0) {
      toast.error('Cannot run empty code');
      return;
    }
    event.preventDefault();
    setIsRunning(true);
    const compilerUrl = 'compile/compile-source';
    const endPoint = config.API_URL + compilerUrl;

    try {
      const response = await axios.post(
        endPoint,
        {
          sourceCodeText: source,
          input: input,
        },
        { headers: { Authorization: token } },
      );

      setOutput(response.data.output);
      setRunCompilerInfo(response.data.cmpinfo);
      setRunErrorInfo(response.data.errorInfo);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsRunning(false);
    }
  }

  const handleSubmit = async () => {
    if (source.length === 0) {
      toast.error('Cannot submit empty code');
      return;
    }
    const submitUrl = 'compile/submit-source';
    const endPoint = config.API_URL + submitUrl;
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        endPoint,
        {
          assignmentId: studentSelectedProblemId,
          submitSourceCode: source,
          problemId: sphereId,
        },
        { headers: { Authorization: token } },
      );
      console.log(response);

      console.log('🚀 ~ handleSubmit ~ response:', response);
      setTestcases(response.data.data);
      setDebuginfo(response.data.debugInfo);
      setCompilerInfo(response.data.cmpinfo);
      toast.success(response.data.grade);
      showModal();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const languages = [
    // { label: 'Java', value: 'java' },
    // { label: 'JavaScript', value: 'javascript' },
    { label: 'Python', value: 'python' },
    // ... add other languages here ...
  ];

  const themes = [
    { label: 'Light', value: 'github' },
    { label: 'Dark', value: 'monokai' },
    // ... add other themes here ...
  ];

  return (
    <div className="compiler">
      <div className="toolbar">
        <select
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          className="dropdown theme-dropdown"
        >
          {themes.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label} Theme
            </option>
          ))}
        </select>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className="dropdown language-dropdown"
        >
          {languages.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <AceEditor
        mode={language}
        theme={theme}
        onChange={onChange}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        value={source}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
          fontSize: 17,
        }}
        style={{ width: '100%', height: '300px' }}
      />

      <div className="compile-run-button">
        <button onClick={handleRun} className="run-button">
          <FontAwesomeIcon icon={faPlay} />
          {isRunning ? 'Running...' : 'Run'}
        </button>

        <button onClick={handleSubmit} className="compile-button">
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>

      {/* Input div if needed uncomment this */}

      {/* <div className="input-div">
        <p>Input: </p>
        <input
          className="input-bar"
          onChange={handleInputChange}
          value={input}
          type="text"
        />
      </div> */}
      <div className="output">
        <h2>Output: </h2>
        <pre>{output}</pre>
        <br />
        <h2>Compiler:</h2>
        <pre>{runCompilerInfo}</pre>
        <br />
        <h2>Error:</h2>
        <pre>{runErrorInfo}</pre>
      </div>
      <div className="submit-output">
        <h2 style={{ fontWeight: 600 }}>Debug Info:</h2>
        <pre>{debuginfo}</pre>
        <br />
        <h2 style={{ fontWeight: 600 }}>Test Cases: </h2>
        <br />
        <div className="test-cases-div">
          {testcases?.map((testcase, index) => {
            return (
              <div className="one-test-case-div">
                <h1>Testcase {index + 1}</h1>
                <p>{testcase.memory}</p>
                <p>{testcase.score}</p>
                <p>{testcase.status.name}</p>
                <br />
              </div>
            );
          })}
        </div>
        <h2 style={{ fontWeight: 600 }}>Compiler :</h2>
        <br />
        <pre>{compilerInfo}</pre>
        <br />
      </div>
      <Modal
        title="Congratulations"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Your code passed all test cases!</p>
        <b style={{ fontWeight: 600, color: 'green' }}>Strengths</b>
        <p>
          Your code effectively calculates the Net Run Rate (NRR)
          straightforwardly and concisely, showcasing a good understanding of
          Python arithmetic operations.
        </p>
        <b style={{ fontWeight: 600, color: 'red' }}>Weakness</b>
        <p>
          Areas for Improvement: Adding error handling for division by zero and
          input validation would enhance the code's robustness and user
          experience, preventing potential runtime errors.
        </p>
      </Modal>
    </div>
  );
}

export default Compiler;
