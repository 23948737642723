import React, { useEffect, useState } from 'react';
import './ProblemsList.css';
import { useNavigate } from 'react-router-dom';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AssignmentTable from './AssignmentTable';

const AssignmentList = () => {
  const selectedChapterId = useSelector(
    (state) => state.selectedChapterId.selectedChapterId,
  );
  const selectedAssignmentIds = useSelector(
    (state) => state.selectedAssignmentIds.selectedAssignmentIds,
  );
  console.log('Received chapterId in AssignmentList:', selectedChapterId);
  console.log('Received problemId in AssignmentList:', selectedAssignmentIds);
  const [disabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  function handleAssignButtonClick() {
    navigate('/student-list');
  }
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(selectedAssignmentIds);
    setIsDisabled(true);
    if (selectedAssignmentIds.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedAssignmentIds]);

  return (
    <div className="main-container">
      <HamburgerMenuTeacher />
      <section>
        <AssignmentTable chapterId={selectedChapterId} />
        <div className="assign-div">
          <button
            disabled={disabled}
            className={disabled ? 'disable-button' : 'assign-button'}
            onClick={handleAssignButtonClick}
            // className="assign-button"
          >
            Assign to Students
          </button>
        </div>
      </section>
    </div>
  );
};

export default AssignmentList;
