import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Login.css'; // Your CSS file for styling
import Button from '../../utils/Button/Button';
import { config } from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, clearUser, setUserType } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import toast from 'react-hot-toast';
const LoginComponent = ({ userType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    // Implement the login logic here
    setIsLoading(true);
    console.log(`Logging in as ${userType} with email: ${email}`);
    // After successful login, redirect or perform other actions
    if (!email) {
      console.log('Please enter email.');
    } else if (!password) {
      console.log('Please enter password.');
    } else {
      let userEndpoint = '';
      let requestData = '';
      if (userType === 'Student') {
        userEndpoint = 'student/studentlogin';
        requestData = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set Content-Type to application/x-www-form-urlencoded
          },
          body: JSON.stringify({
            student_email: email,
            student_passcode: password,
          }),
        };
      } else if (userType === 'Organisation') {
        userEndpoint = 'student/studentlogin';
      } else if (userType === 'Teacher') {
        userEndpoint = 'teacher/teacher-login';
        requestData = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set Content-Type to application/x-www-form-urlencoded
          },
          body: JSON.stringify({
            teacher_email: email,
            teacher_passcode: password,
          }),
        };
      } else {
        return;
      }

      let formData = new URLSearchParams();
      formData.append('email', email);
      formData.append('password', password);
      // console.log(formData)
      const endpoint = config.API_URL + userEndpoint;
      fetch(endpoint, requestData)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.success === false) toast.error(data.message);
          setIsLoading(false);
          if (data.success) {
            //set User
            //redux
            dispatch(setUser(data.data));
            //set UserType
            dispatch(setUserType(data.userType));
            //set Token
            //redux
            dispatch(setToken(data.token));

            //student student-dashboard
            if (data.userType === 'Student') {
              navigate('/subjects');
            } else if (data.userType === 'Teacher') {
              navigate('/teacher-dashboard');
            } else {
              alert('Error');
            }
            //teacher teacher-dashboard
            // navigate('/dashboard');
          } else {
            console.log('In false');
            // navigate(`/login/${userType}`)
            // navigate(`/login`);
          }
        })
        .catch((error) => {
          console.log('🚀 ~ handleLogin ~ error:', error);
        });
      console.log('in handle sign in');
    }
  };

  const navigateToChangePassword = () => {
    navigate('/changePassword');
  };

  return (
    <div className="main-background">
      <div className="inner-card">
        <div className="login-container">
          <form onSubmit={handleLogin}>
            <h2 className="login-head">Login as {userType}</h2>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button
              disabled={isLoading}
              text={isLoading ? 'Logging In' : 'Login'}
              className="login-button"
            />

            <a href={navigateToChangePassword} className="login-link">
              Forgot Password?
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  // ... (other styles)
  link: {
    // marginTop:'40px',
    color: 'blue',
    textDecoration: 'none', // Removes the underline from the link
    cursor: 'pointer', // Changes the cursor to indicate it's clickable
    // Add other styles here if necessary
  },
  // ... (other styles)
};

export default LoginComponent;
