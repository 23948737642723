import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import axios from 'axios';

import { useSelector } from 'react-redux';
const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Student Name',
    dataIndex: 'student_name',
    key: 'name',
    render: (text) => <p style={{ fontWeight: 600 }}>{text}</p>,
  },
  {
    title: 'Email',
    dataIndex: 'student_email',
    key: 'name',
  },
  {
    title: 'Contact',
    dataIndex: 'student_contact',
    key: 'name',
  },
  {
    title: 'Roll No.',
    dataIndex: 'student_id',
    key: 'name',
  },
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'name',
  },
];

const ViewStudentsTable = ({ studentClass }) => {
  const [students, setStudents] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const { token } = useAppContext();
  const token = useSelector((state) => state.token.token);
  const [loading, setLoading] = useState(false);
  const [selectedProblemIds, setSelectedProblemIds] = useState([]);

  useEffect(() => {
    async function getStudentList() {
      setLoading(true);
      const studentListUrl = 'teacher/get-students';
      const endPoint = config.API_URL + studentListUrl + '/' + studentClass;
      try {
        const response = await axios.get(endPoint, {
          headers: { Authorization: token },
        });
        // setStudents(response.data.data);
        setStudents(
          response.data.data.map((student, index) => ({
            ...student,
            key: index, // Ensure each problem has a unique key for selection
          })),
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getStudentList();
  }, [token, studentClass]);

  return <Table columns={columns} dataSource={students} loading={loading} />;
};
export default ViewStudentsTable;
