import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { config } from '../../../config';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { setSelectedStudentIds } from '../../../redux/slices/selectionSlice';
const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Student Name',
    dataIndex: 'student_name',
    key: 'name',
    render: (text) => <p style={{ fontWeight: 600 }}>{text}</p>,
  },
  {
    title: 'Email',
    dataIndex: 'student_email',
    key: 'name',
  },
  {
    title: 'Contact',
    dataIndex: 'student_contact',
    key: 'name',
  },
  {
    title: 'Roll No.',
    dataIndex: 'student_id',
    key: 'name',
  },
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'name',
  },
];

const StudentTable = ({ studentClass }) => {
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  const [students, setStudents] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const token = useSelector((state) => state.token.token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getStudentList() {
      setLoading(true);
      const studentListUrl = 'teacher/get-students';
      const endPoint = config.API_URL + studentListUrl + '/' + studentClass;
      const response = await axios.get(endPoint, {
        headers: { Authorization: token },
      });
      setStudents(
        response.data.data.map((student, index) => ({
          ...student,
          key: index, // Ensure each problem has a unique key for selection
        })),
      );
      console.log(response);
      setLoading(false);
    }
    getStudentList();
  }, [token, studentClass]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Map selected row keys to their _id
    const selectedIds = newSelectedRowKeys
      .map((key) => students.find((student) => student.key === key)?._id)
      .filter((id) => id !== undefined);
    dispatch(setSelectedStudentIds(selectedIds)); // Dispatch the setSelectedWeekId action with the selected weekId

    console.log('Selected student IDs:', selectedIds);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
  };

  return (
    <Table
      columns={columns}
      rowSelection={rowSelection}
      dataSource={students}
      loading={loading}
    />
  );
};
export default StudentTable;
