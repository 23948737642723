import React from 'react';
import './MyAccount.css';
import HamburgerMenuStudent from '../User/Student/HamburgerMenuStudent';
import { useSelector } from 'react-redux';
import HamburgerMenuTeacher from '../User/Teacher/HamburgerMenuTeacher';
import { useNavigate } from 'react-router-dom';

const MyAccount = () => {
  const user = useSelector((state) => state.user.user);
  const userType = useSelector((state) => state.user.userType);
  const navigate = useNavigate();
  return (
    <div className="main-container">
      {userType === 'Student' && <HamburgerMenuStudent />}
      {userType === 'Teacher' && <HamburgerMenuTeacher />}

      <section>
        <div className="account-container">
          <img
            className="user-img"
            src={require('../../Assets/img/user-img.jpg')}
            alt="user-img"
          />
          {userType === 'Student' && (
            <ul className="account-li">
              <li>
                <strong style={{ fontWeight: 600 }}>Name </strong>:{' '}
                {user.student_name}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Class </strong>:{' '}
                {user.class}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Section </strong>:{' '}
                {user.section}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Roll No.: </strong>:{' '}
                {user.student_id}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Contact </strong>:{' '}
                {user.student_contact}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Email </strong>:{' '}
                {user.student_email}
              </li>
              <button
                onClick={() => navigate('/view-report')}
                className="view-report-card-button"
              >
                View Report Card
              </button>
            </ul>
          )}

          {userType === 'Teacher' && (
            <ul className="account-li">
              <li>
                <strong style={{ fontWeight: 600 }}>Name </strong>:{' '}
                {user.teacher_name}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Contact </strong>:{' '}
                {user.teacher_contact}
              </li>
              <li>
                <strong style={{ fontWeight: 600 }}>Email </strong>:{' '}
                {user.teacher_email}
              </li>
            </ul>
          )}
        </div>
      </section>
    </div>
  );
};

export default MyAccount;
