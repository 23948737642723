import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Path to your CSS file for HeroSection
import '../../index.css';

import roboImage from '../../Assets/img/robo.png';

const LandingPage = () => {
  return (
    <>
      <div className="hero-section">
        <div className="hero-left">
          <div className="left-section-hero">
            <div className="hero-text">
              <p className="hero-text-heading-blue">
                Innovative Learning, Simplified Management
              </p>
              <p className="hero-text-heading">
                Enhancing Student Learning Outcomes for Schools
              </p>
              <p className="hero-p">
                Partner with us to introduce AI based coding assignments for
                students in grades 6th-10th. Educators, create and manage
                assignments and courses effortlessly. Students, dive into a
                world of knowledge, attend courses, and excel in assignments.
              </p>
            </div>
            <Link to="/book-a-demo" className="hero-demo-link">
              <div className="book-a-demo-button">
                <div className="book-a-demo-frame">
                  <p className="book-a-demo-text">
                    <span className="book-a-demo-span">Book a Demo</span>
                  </p>
                  <img
                    className="arrow-right"
                    src="https://c.animaapp.com/TEOYoSB0/img/arrow-right-1.png"
                    alt="Arrow Right"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="hero-right">
          <img
            className="hero-pointer"
            src="https://c.animaapp.com/TEOYoSB0/img/vector-112.svg"
            alt="Pointer"
          />
          <img className="hero-img" src={roboImage} alt="Robo" />
        </div>
      </div>

      {/* // Our platform */}

      <div class="our-platform">
        <div class="our-platform-heading ">
          <p>
            <span class="text-wrapper-5">
              Our platform provides coding assignments based on
            </span>{' '}
            <span class="text-wrapper-7"> CBSE’s “AI”</span>{' '}
            <span class="text-wrapper-5">subject curriculum. </span>
          </p>
        </div>
        <div class="our-platform-arrow">
          <img
            class="our-platform-arrow-image"
            src={require('../../Assets/img/dividing-arrow.png')}
            alt=""
          />
        </div>
        <div class="our-platform-main-content">
          <div class="our-platform-right ">
            <p class="hero-p">
              <span class="student-bold">Students</span> can solve a number of
              problems, get personalized feedback, and improve their programming
              skills – all while having fun completing their school homework!
            </p>
          </div>
          <div class="our-platform-left ">
            <p class="hero-p">
              <span class="student-bold">Teachers</span> can select assignments
              from our assignment bank( based on CBSE’s curriculum), set due
              dates and can save time with the convenience of automated grading!
            </p>
          </div>
        </div>
      </div>

      {/* OUR Collaboration with school section  */}

      <div class="partners-collaborating">
        <div class="partner-heading">
          <p>
            <span class="text-wrapper-5">Our</span>{' '}
            <span class="text-wrapper-7">School</span>{' '}
            <span class="text-wrapper-5">Collaborations</span>{' '}
          </p>
        </div>
        <div class="banner">
          <div class="images">
            {/* <!-- Repeat your set of images to fill the space --> */}
            <img
              src={require('../../Assets/img/mmPublicSchool.png')}
              alt="School 1"
            />
            {/* <!-- <img src="/ahlconPublicSchool.png" alt="School 2"> --> */}
            {/* <!-- <img src="/mmPublicSchool.png" alt="School 3"> -->
                <!-- <img src="/ahlconPublicSchool.png" alt="School 4"> -->
                <!-- <img src="/mmPublicSchool.png" alt="School 5"> -->
                <!-- <img src="/ahlconPublicSchool.png" alt="School 6"> -->
                <!-- <img src="/mmPublicSchool.png" alt="School 7"> -->
                <!-- <img src="/ahlconPublicSchool.png" alt="School 8"> --> */}
          </div>
        </div>
      </div>

      {/* Pop up video */}

      <button
        id="openPopupButton"
        onClick={() => {
          document.getElementById('videoPopup').style.display = 'block';
          document.getElementById('openPopupButton').style.display = 'none';
        }}
      >
        Product Demo
      </button>

      <div id="videoPopup" style={{ display: 'none' }}>
        <div class="popup-header">
          <h2>Nodify Introduction.</h2>
          <button
            onClick={() => {
              document.getElementById('videoPopup').style.display = 'none';
              document.getElementById('openPopupButton').style.display =
                'block';
            }}
          >
            X
          </button>
        </div>
        {/* <!--Embed your video here --> */}
        <iframe
          title="demo"
          width="300"
          height="169"
          src="https://www.youtube.com/embed/5R1qLxWqrZE?si=2uZQQ8GEdsGDY9Sf"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      {/* Why Nodify Section */}

      <div class="why-nodify-section">
        <div class="why-nodify">
          <p>
            <span class="text-wrapper-5">Why</span>
            <span class="text-wrapper-6">&nbsp;</span>
            <span class="text-wrapper-7">Nodify?</span>
          </p>
        </div>

        <div class="main-sections">
          {/* <!-- section - 1 --> */}
          <div class="section-content">
            <div class="section-text">
              <img src={require('../../Assets/img/01.png')} alt="" />
              <p class="content-heading">Effortless Assignment Creation</p>
              <p class="hero-p">
                Teacher can either choose an automated generated assignment (
                based on CBSE coursework) or upload an assignment of his/her
                choice
              </p>
            </div>
            <div class="section-image img-1">
              <img src={require('../../Assets/img/13-1-2.png')} alt="" />
            </div>
          </div>

          {/* <!-- arrow top --> */}

          <div class="left-arrow">
            <img src={require('../../Assets/img/arrow-left-1.png')} alt="" />
          </div>
          {/* <!-- section - 2 --> */}
          <div class="section-content">
            <div class="section-image img-2">
              <img src={require('../../Assets/img/13-1-1.png')} alt="" />
            </div>
            <div class="section-text odd-text">
              <img src={require('../../Assets/img/02.png')} alt="" />
              <p class="content-heading">Engaging Coding Assignments</p>
              <p class="hero-p">
                Student gets introduced to a coding environment, which simulates
                real-world problem solving. Designed to improve student learning
                outcomes for learning technical skills
              </p>
            </div>
          </div>

          {/* <!-- arrow center --> */}
          <div class="arrow-center">
            <img src={require('../../Assets/img/arrow center.png')} alt="" />
          </div>
          {/* <!-- section - 3 --> */}
          <div class="section-content">
            <div class="section-text odd-text">
              <img src={require('../../Assets/img/03.png')} alt="" />
              <p class="content-heading">
                Automated Grading & Personalized Feedback
              </p>
              <p class="hero-p">
                Each student receives a score with a personalized feedback.
                Student is able to understand his/her mistake and the teacher
                can easily access each student’s grade
              </p>
            </div>
            <div class="section-image img-3">
              <img src={require('../../Assets/img/13-1.png')} alt="" />
            </div>
          </div>
          <div class="bottom-arrow">
            <img src={require('../../Assets/img/arrow-bottom.png')} alt="" />
          </div>

          {/* <!-- section - 4 --> */}
          <div class="section-content">
            <div class="section-image img-4">
              <div class="laptop-background">
                <img src={require('../../Assets/img/05-1.png')} alt="" />
              </div>
            </div>
            <div class="section-text">
              <img src={require('../../Assets/img/04.png')} alt="" />
              <p class="content-heading">Track Student Progress</p>
              <p class="hero-p">
                Our platform allows teachers, students, and parents to track a
                child’s growth. Not only do we provide coding homework, but also
                practice material that helps the child learn more!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
