import React from 'react';
import Problems from './Problems';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ProblemsPage = () => {
  // const { selectedWeekId } = useAppContext();
  const selectedWeekId = useSelector(
    (state) => state.selectedWeekId.selectedWeekId,
  );
  const location = useLocation();
  const { filterStatus } = location.state;
  console.log('🚀 ~ ProblemsPage ~ filterStatus:', filterStatus);

  return (
    <div className="main-container">
      <HamburgerMenuStudent />
      <section>
        <Problems filterStatus={filterStatus} weekId={selectedWeekId} />
      </section>
    </div>
  );
};

export default ProblemsPage;
