import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import MainNavigation from './components/Navigation/MainNaviagtion';
import LandingPage from './components/LandingPage/LandingPage';
import Login from './components/Login/Login';
import TeacherLogin from './components/Login/Teacher/TeacherLogin';
import StudentLogin from './components/Login/Student/StudentLogin';
import OrganisationLogin from './components/Login/Organisation/OrganisationLogin';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import BookADemo from './components/ContactUs/Demo';
import TeacherDashboard from './components/User/Teacher/TeacherDashboard';
import StudentDashboard from './components/User/Student/StudentDashboard';
import ProblemsPage from './components/User/Student/ProblemsPage';
import { AppProvider } from './AppContext';
import CompilerPage from './components/Compiler/CompilerPage';
import StudentList from './components/User/Teacher/StudentList';
import UploadCsv from './components/User/Teacher/UploadCsv';
import CreateAssignment from './components/User/Teacher/CreateAssignment';
import ProblemsList from './components/User/Teacher/ProblemsList';
import ViewStudentsList from './components/User/Teacher/ViewStudentsList';
import MyAccount from './components/My Account/MyAccount';
import HelpMe from './components/Help Me/HelpMe';
import ViewReportCard from './components/View Report Card/ViewReportCard';
import ProtectedRoute from './ProtectedRoute';
import Subjects from './components/User/Student/Subjects';
import AssignmentList from './components/User/Teacher/AssignmentList';
import MathChapterListStudent from './components/User/Student/MathChapterListStudent';
import MathAssignmentListStudent from './components/User/Student/MathAssignmentsListStudent';
import MathQuestionsListStudent from './components/User/Student/MathQuestionsListStudent';

function FooterRenderer() {
  const location = useLocation();

  // Check if the current pathname matches any of the conditions
  const hideFooter =
    location.pathname === '/compiler' ||
    location.pathname === '/student-dashboard' ||
    location.pathname === '/teacher-dashboard' ||
    location.pathname === '/student-list' ||
    location.pathname === '/problemspage' ||
    location.pathname === '/create-assignment' ||
    location.pathname === '/problem-student' ||
    location.pathname === '/problem-list' ||
    location.pathname === '/view-students-list' ||
    location.pathname === '/my-account' ||
    location.pathname === '/help-me' ||
    location.pathname === '/view-report' ||
    location.pathname === '/subjects' ||
    location.pathname === '/math-assignments' ||
    location.pathname === '/student/math-chapters' ||
    location.pathname === '/student/math-assignments' ||
    location.pathname === '/student/math-questions' ||
    location.pathname === '/upload-csv';

  // If hideFooter is true, don't render the footer
  if (hideFooter) {
    return null;
  }

  // Render the footer if hideFooter is false
  return <Footer />;
}

function App() {
  // const location = useLocation();
  // const hideFooter = location.pathname === '/compiler';
  return (
    <Router>
      <AppProvider>
        <MainNavigation />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/organisation" element={<OrganisationLogin />} />
          <Route path="/login/Teacher" element={<TeacherLogin />} />
          <Route path="/login/Student" element={<StudentLogin />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/book-a-demo" element={<BookADemo />} />

          <Route
            path="/teacher-dashboard"
            element={
              <ProtectedRoute>
                <TeacherDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-dashboard"
            element={
              <ProtectedRoute>
                <StudentDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/problemspage"
            element={
              <ProtectedRoute>
                <ProblemsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/compiler"
            element={
              <ProtectedRoute>
                <CompilerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-list"
            element={
              <ProtectedRoute>
                <StudentList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-csv"
            element={
              <ProtectedRoute>
                <UploadCsv />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-assignment"
            element={
              <ProtectedRoute>
                <CreateAssignment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/problem-list"
            element={
              <ProtectedRoute>
                <ProblemsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-students-list"
            element={
              <ProtectedRoute>
                <ViewStudentsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help-me"
            element={
              <ProtectedRoute>
                <HelpMe />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-report"
            element={
              <ProtectedRoute>
                <ViewReportCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subjects"
            element={
              <ProtectedRoute>
                <Subjects />
              </ProtectedRoute>
            }
          />
          <Route
            path="/math-assignments"
            element={
              <ProtectedRoute>
                <AssignmentList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/math-chapters"
            element={
              <ProtectedRoute>
                <MathChapterListStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/math-assignments"
            element={
              <ProtectedRoute>
                <MathAssignmentListStudent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/math-questions"
            element={
              <ProtectedRoute>
                <MathQuestionsListStudent />
              </ProtectedRoute>
            }
          />
        </Routes>
        {/* Conditionally render the footer based on the path */}
        <FooterRenderer />
        {/* {!hideFooter && <Footer />} */}

        {/* <Footer /> */}
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              background: '#363636',
              color: '#fff',
              fontFamily: 'Degular-Semibold, Helvetica',
            },
          }}
        />
      </AppProvider>
    </Router>
  );
}

export default App;
