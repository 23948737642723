// AppContext.js
import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userType, setUserType] = useState('');
  const [token, setToken] = useState('');
  const [selectedWeekId, setSelectedWeekId] = useState(null);
  const [selectedProblemIds, setSelectedProblemIds] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);

  const [problems, setProblems] = useState([]);

  const formatTime = (date) => {
    if (date === null) {
      return '-';
    }
    const now = new Date();
    const s = Math.abs(now - new Date(date)) / 1000;
    if (s < 60) {
      return 'now';
    }
    if (s < 60 * 60) {
      const m = Math.floor(s / 60);
      return `${m}m ago`;
    }
    if (s < 60 * 60 * 24) {
      const h = Math.floor(s / (60 * 60));
      return `${h}h ago`;
    }
    if (s < 60 * 60 * 24 * 7) {
      const d = Math.floor(s / (60 * 60 * 24));
      return `${d}d ago`;
    }
    if (s < 60 * 60 * 24 * 7 * 4) {
      const w = Math.floor(s / (60 * 60 * 24 * 7));
      return `${w}w ago`;
    }
    const y = Math.floor(s / (60 * 60 * 24 * 365));
    return `${y}y ago`;
  };

  const value = {
    user,
    setUser,
    userType,
    setUserType,
    token,
    setToken,
    formatTime,
    selectedWeekId,
    setSelectedWeekId,
    selectedProblemIds,
    setSelectedProblemIds,
    problems,
    setProblems,
    selectedStudentIds,
    setSelectedStudentIds,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
