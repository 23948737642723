import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  setMathAssignments,
  setStudentSelectedMathAssignmentId,
} from '../../../redux/slices/selectionSlice';

const columns = (handleSelectAssignment) => [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Assignment Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => (
      <Link
        to="/student/math-questions"
        onClick={(e) => handleSelectAssignment(e, record.key)}
        style={{ fontWeight: 600 }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: 'Marks',
    dataIndex: 'marks',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'name',
    render: (text) => (
      <p
        style={{
          fontWeight: 600,
          color:
            text === 'completed' ? 'green' : text === 'pending' ? 'red' : '',
        }}
      >
        {text}
      </p>
    ),
  },
  {
    title: 'Obtained Marks',
    dataIndex: 'finalMarks',
    key: 'name',
  },
  {
    title: 'Last Date',
    dataIndex: 'lastDate',
    key: 'name',
  },
];

const MathAssignmentTableStudent = ({
  filterStatus,
  onAssignmentSelect,
  chapterId,
}) => {
  const dispatch = useDispatch(); // Initialize useDispatch to dispatch actions

  const handleSelectAssignment = (e, assignmentId) => {
    dispatch(setStudentSelectedMathAssignmentId(assignmentId));
    onAssignmentSelect(assignmentId);
  };

  const token = useSelector((state) => state.token.token);
  const mathAssignments = useSelector(
    (state) => state.mathAssignments.mathAssignments,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getMathAssignmentList() {
      setLoading(true);
      const assignmentListUrl = 'student/math/get-assignments';
      const endPoint = config.API_URL + assignmentListUrl + '/' + chapterId;
      const response = await axios.get(endPoint, {
        headers: { Authorization: token },
      });
      console.log(response);

      const filteredMathAssignments =
        filterStatus === 'all'
          ? response.data.data
          : response.data.data.filter(
              (mathAssignment) => mathAssignment.status === filterStatus,
            );
      dispatch(
        setMathAssignments(
          filteredMathAssignments.map((problem) => ({
            ...problem,
            key: problem._id,
          })),
        ),
      );

      setLoading(false);
    }
    getMathAssignmentList();
  }, [token, chapterId, dispatch, filterStatus]);

  return (
    <Table
      columns={columns(handleSelectAssignment)}
      onRow={(record) => ({
        onClick: () => handleSelectAssignment({}, record.key),
      })}
      dataSource={mathAssignments}
      loading={loading}
    />
  );
};
export default MathAssignmentTableStudent;
