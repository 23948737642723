import React, { useState, useEffect } from 'react';
import { Table, Tag, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../AppContext';
import { config } from '../../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';

let newFilterStatus = '';

const columns = [
  {
    title: 'No.',
    dataIndex: 'index',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Subjects',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => (
      <Link
        to={
          text === 'Computer Science'
            ? '/student-dashboard'
            : '/student/math-chapters'
        }
        // state={{ filterStatus: newFilterStatus }}
        style={{ fontWeight: 600 }}
      >
        {text}
      </Link>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'Computer Science',
    age: 32,
    points: 45,
    time: '14h',
  },
  {
    key: '2',
    name: 'Mathematics',
    age: 42,
    points: 30,
    time: '10h',
  },
];

const SubjectsTable = () => {
  return <Table columns={columns} dataSource={data} />;
};
export default SubjectsTable;
