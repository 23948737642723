// src/components/HamburgerMenuStudent.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  faBars,
  faAnglesLeft,
  faBookOpen,
  faUser,
  faTableList,
  faHourglass,
  faCheckDouble,
  faBook,
  faDesktop,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons';
import './HamburgerMenuStudent.css';
import { Button, Menu } from 'antd';

const HamburgerMenuStudent = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // New state to manage filter status
  const [filterStatus, setFilterStatus] = useState('all'); // all, completed, pending

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Updated navigation logic to include filter status
  const handleNavigation = (path, status = 'all') => {
    setFilterStatus(status);

    navigate(path, { state: { filterStatus: status } }); // Assuming you'll retrieve this state in the target component
  };

  return (
    <div>
      {isOpen ? (
        <section className="left-section-small">
          <div
            style={{ visibility: isOpen ? 'visible' : 'hidden' }}
            className="hamburger"
            onClick={toggleMenu}
          >
            <FontAwesomeIcon className="hamburger-icon" icon={faBars} />
          </div>
          <div className="admin-actions-small">
            <Menu defaultOpenKeys={['sub1']} mode="inline">
              <Menu.SubMenu
                key="sub1"
                icon={<FontAwesomeIcon icon={faBookOpen} />}
              >
                <Menu.Item
                  onClick={() => handleNavigation('/student-dashboard', 'all')}
                  icon={<FontAwesomeIcon icon={faTableList} />}
                >
                  Current Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student-dashboard', 'completed')
                  }
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                >
                  Completed Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student-dashboard', 'pending')
                  }
                  icon={<FontAwesomeIcon icon={faHourglass} />}
                >
                  Pending Assignments
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu
                key="sub2"
                icon={<FontAwesomeIcon icon={faCalculator} />}
              >
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'all')
                  }
                  icon={<FontAwesomeIcon icon={faTableList} />}
                >
                  Current Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'completed')
                  }
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                >
                  Completed Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'pending')
                  }
                  icon={<FontAwesomeIcon icon={faHourglass} />}
                >
                  Pending Assignments
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item
                onClick={() => navigate('/subjects')}
                icon={<FontAwesomeIcon icon={faBook} />}
              ></Menu.Item>
              <Menu.Item
                onClick={() => navigate('/my-account')}
                icon={<FontAwesomeIcon icon={faUser} />}
              ></Menu.Item>
            </Menu>
          </div>
        </section>
      ) : (
        <section className="left-section">
          <div className="assignment-and-menu">
            <FontAwesomeIcon
              className="hamburger-icon"
              onClick={toggleMenu}
              icon={faAnglesLeft}
            />
          </div>
          <div className="admin-actions">
            <Menu defaultOpenKeys={['sub1', 'sub2']} mode="inline">
              <Menu.SubMenu
                icon={<FontAwesomeIcon icon={faDesktop} />}
                title="CS Assignments"
                key="sub1"
              >
                <Menu.Item
                  onClick={() => handleNavigation('/student-dashboard', 'all')}
                  icon={<FontAwesomeIcon icon={faTableList} />}
                >
                  Current Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student-dashboard', 'completed')
                  }
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                >
                  Completed Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student-dashboard', 'pending')
                  }
                  icon={<FontAwesomeIcon icon={faHourglass} />}
                >
                  Pending Assignments
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu
                icon={<FontAwesomeIcon icon={faCalculator} />}
                title="Math Assignments"
                key="sub2"
              >
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'all')
                  }
                  icon={<FontAwesomeIcon icon={faTableList} />}
                >
                  Current Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'completed')
                  }
                  icon={<FontAwesomeIcon icon={faCheckDouble} />}
                >
                  Completed Assignments
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleNavigation('/student/math-chapters', 'pending')
                  }
                  icon={<FontAwesomeIcon icon={faHourglass} />}
                >
                  Pending Assignments
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item
                onClick={() => navigate('/subjects')}
                icon={<FontAwesomeIcon icon={faBook} />}
              >
                Subjects
              </Menu.Item>
              <Menu.Item
                onClick={() => navigate('/my-account')}
                icon={<FontAwesomeIcon icon={faUser} />}
              >
                My Account
              </Menu.Item>
            </Menu>
          </div>
        </section>
      )}
    </div>
  );
};

export default HamburgerMenuStudent;
