import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../AppContext';
import './ViewStudentsList.css';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import HamburgerMenuTeacher from './HamburgerMenuTeacher';
import { DatePicker, Space } from 'antd';
import ViewStudentsTable from './ViewStudentsTable';
import { useLocation } from 'react-router-dom';
const { RangePicker } = DatePicker;

const ViewStudentsList = () => {
  const location = useLocation();
  const { studentClass } = location.state;

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { selectedProblemIds } = useAppContext();
  console.log(selectedProblemIds);

  return (
    <div className="main-container">
      <HamburgerMenuTeacher />
      <section>
        <Select
          mode="multiple"
          placeholder="Select Sections"
          // defaultValue="Section A"
          onChange={handleChange}
          options={[
            {
              options: [
                { label: 'Section A', value: 'Section A' },
                { label: 'Section B', value: 'Section B' },
                { label: 'Section C', value: 'Section C' },
                { label: 'Section D', value: 'Section D' },
              ],
            },
          ]}
        />
        <ViewStudentsTable studentClass={studentClass} />
      </section>
    </div>
  );
};

export default ViewStudentsList;
