// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  userType:JSON.parse(localStorage.getItem('userType')) || null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.removeItem('user');
    },
    setUserType:(state,action)=>{
      state.userType = action.payload;
      localStorage.setItem('userType', JSON.stringify(action.payload));
    },
    clearUserType:(state,action) =>{
      state.userType = null;
      localStorage.removeItem('userType');
    }
  },
});

export const { setUser, clearUser,setUserType,clearUserType } = userSlice.actions;
export default userSlice.reducer;
