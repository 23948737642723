import React, { useEffect, useState } from 'react';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Radio, Select, Space } from 'antd';
import HamburgerMenuStudent from './HamburgerMenuStudent';
import { config } from '../../../config';
import axios from 'axios';
import './MathQuestionsListStudent.css';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const MathQuestionsListStudent = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const token = useSelector((state) => state.token.token);
  const studentSelectedMathAssignmentId = useSelector(
    (state) =>
      state.studentSelectedMathAssignmentId.studentSelectedMathAssignmentId,
  );
  const [loading, setLoading] = useState(false);

  const onChangeOptions = (questionId, option) => {
    setSelectedOptions({ ...selectedOptions, [questionId]: option });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.API_URL}student/math/submit-problem`,
        {
          assignmentId: studentSelectedMathAssignmentId,
          answerArray: selectedOptions,
        },
        { headers: { Authorization: token } },
      );
      console.log('🚀 ~ handleSubmit ~ response:', response);
      toast.success(response.data.message);
      toast.success('Your Score is:', response.data.data);
      navigate('/subjects');
      // Optionally, dispatch an action to update Redux store or handle success
    } catch (error) {
      toast.error(error.response.data.message);
      console.log('Error submitting answers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getQuestionsStudent() {
      setLoading(true);
      const questionListUrl = 'student/math/get-questions';
      const endPoint =
        config.API_URL +
        questionListUrl +
        '/' +
        studentSelectedMathAssignmentId;
      try {
        const response = await axios.get(endPoint, {
          headers: { Authorization: token },
        });
        console.log(response);
        const questionsData = response.data.data.map((question, index) => ({
          ...question,
          key: question._id, // Assuming 'id' is the unique ID field in your response data
          index: index + 1, // Add index if you need to keep the No. column accurate after operations like sorting
        }));
        setQuestions(questionsData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getQuestionsStudent();
  }, [token, studentSelectedMathAssignmentId]);
  return (
    <div className="main-container">
      {console.log(selectedOptions)}
      <HamburgerMenuStudent />
      <section>
        {questions ? (
          questions.map((question) => (
            <div className="question-container" key={question._id}>
              <h2 style={{ fontWeight: 600 }}>{question.question}</h2>
              {question.options?.map((option, index) => (
                <Radio.Group
                  key={`${question._id}-${index}`}
                  // onChange={onChangeOptions}
                  onChange={(e) =>
                    onChangeOptions(question._id, e.target.value)
                  }
                  value={selectedOptions[question._id]}
                  // value={question.correctOption}
                >
                  <Space direction="vertical">
                    <Radio
                      // onChange={onChangeOptions}
                      disabled={false}
                      key={`${option}-${index}`}
                      value={option}
                    >
                      {option}
                    </Radio>
                  </Space>
                </Radio.Group>
              ))}
              <br />
            </div>
          ))
        ) : (
          <div className="question-container">Loading Question...</div>
        )}
        <div className="submit-div">
          <Button
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            className="math-question-submit"
          >
            Submit
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MathQuestionsListStudent;
