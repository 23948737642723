import React, { useEffect, useState } from 'react';
import Compiler from './Compiler';
import './CompilerPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faAnglesLeft,
  faBookOpen,
  faTableList,
  faTimes,
  faLightbulb,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Menu, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { config } from '../../config';
import axios from 'axios';

const CompilerPage = () => {
  const studentSelectedProblemId = useSelector(
    (state) => state.studentSelectedProblemId.studentSelectedProblemId,
  );
  const [isOpen, setIsOpen] = useState(false);

  const token = useSelector((state) => state.token.token);
  const [problem, setProblem] = useState();
  const [sphereId, setSphereId] = useState();
  const problems = useSelector((state) => state.problems.problems);
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // to show the problem in problem container
    async function getProblem() {
      const getProblemURL = 'student/get-problem';
      const endPoint =
        config.API_URL + getProblemURL + '/' + studentSelectedProblemId;
      try {
        const response = await axios.get(endPoint, {
          headers: { Authorization: token },
        });
        setProblem(response.data.data);
        setSphereId(response.data.data.sphereId);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    getProblem();
  }, [token, studentSelectedProblemId]);

  const handleShowVideo = () => {
    setShowVideo(true);
  };

  return (
    <>
      <div className="compiler-page-container">
        <div>
          {isOpen ? (
            <section className="left-section-small">
              <div
                style={{ visibility: isOpen ? 'visible' : 'hidden' }}
                className="hamburger"
                onClick={toggleMenu}
              >
                <FontAwesomeIcon className="hamburger-icon" icon={faBars} />
              </div>
              <div className="admin-actions-small"></div>

              <div className="admin-help"></div>
            </section>
          ) : (
            <section className="left-section">
              <div className="assignment-and-menu">
                {/* <button className="assignment-button">+ Create Assignment</button> */}
                <FontAwesomeIcon
                  className="hamburger-icon"
                  onClick={toggleMenu}
                  icon={faAnglesLeft}
                />
              </div>
              <div className="admin-actions">
                <Menu defaultOpenKeys={['sub1', 'sub2']} mode="inline">
                  <Menu.SubMenu
                    icon={<FontAwesomeIcon icon={faBookOpen} />}
                    title="Problems"
                    key="sub1"
                  >
                    {problems.map((problem) => {
                      return (
                        <Menu.Item
                          key={problem._id}
                          icon={<FontAwesomeIcon icon={faTableList} />}
                        >
                          {problem.question}
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                  <Menu.SubMenu
                    title="Show Hints"
                    icon={<FontAwesomeIcon icon={faLightbulb} />}
                    key="sub2"
                  >
                    <Menu.Item
                      onClick={toggleVideo}
                      icon={<FontAwesomeIcon icon={faVideo} />}
                    >
                      Show Explanation
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
                <div
                  className="video-div"
                  style={{ display: showVideo ? 'flex' : 'none' }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={toggleVideo}
                    className="video-close-icon"
                  />
                  <iframe
                    className="compiler-page-iframe"
                    width="290"
                    height="300"
                    src="https://www.youtube.com/embed/vbQBQrmwt4s?si=WRq6NEUM6IM8G3VU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </section>
          )}
        </div>
        {problem ? (
          <div className="problem-container">
            <h2 className="problem-name" style={{ fontWeight: 600 }}>
              {problem.question}
            </h2>
            <br />
            <p className="problem-description">{problem.description}</p>
            <br />
            {problem.testcases.map((testcase, index) => (
              <div key={index} className="problem-examples">
                <h2>{`Example ${index + 1}`}</h2>
                <br />
                <h4>
                  <b style={{ fontWeight: 600 }}>Input: </b>
                  {testcase.input.split('\\n').map((num) => (
                    <p>{num}</p>
                  ))}
                </h4>
                <h4>
                  <b style={{ fontWeight: 600 }}>Output: </b>
                  {`${testcase.output}`}
                </h4>

                <br />
              </div>
            ))}
          </div>
        ) : (
          <div className="problem-container">
            <h2>Problem Loading...</h2>
          </div>
        )}

        <div className="compiler-container">
          <Compiler sphereId={sphereId} />
        </div>
      </div>
    </>
  );
};

export default CompilerPage;
