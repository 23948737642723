// rootReducer.js
import { combineReducers } from 'redux';
import userReducer from './slices/userSlice.js';
import tokenReducer from './slices/tokenSlice.js';
import { reducers } from './slices/selectionSlice.js';

const rootReducer = combineReducers({
  user: userReducer,
  token: tokenReducer,
  selectedSubject: reducers.selectedSubject,
  selectedWeekId: reducers.selectedWeekId,
  selectedChapterId: reducers.selectedChapterId,
  studentSelectedProblemId: reducers.studentSelectedProblemId,
  studentSelectedMathAssignmentId: reducers.studentSelectedMathAssignmentId,
  selectedProblemIds: reducers.selectedProblemIds,
  selectedAssignmentIds: reducers.selectedAssignmentIds,
  selectedStudentIds: reducers.selectedStudentIds,
  problems: reducers.problems,
  mathAssignments: reducers.mathAssignments,
});

export default rootReducer;
