import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import '../../index.css';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearUser, clearUserType } from '../../redux/slices/userSlice';
import { clearToken } from '../../redux/slices/tokenSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faArrowRightFromBracket,
  faUser,
  faBell,
  faComment,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';

import logoImage from '../../Assets/img/Nodify.png';
import { useAppContext } from '../../AppContext';

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import {
  clearMathAssignments,
  clearProblems,
  clearSelectedAssignmentIds,
  clearSelectedChapterId,
  clearSelectedProblemIds,
  clearSelectedStudentIds,
  clearSelectedSubject,
  clearSelectedWeekId,
  clearStudentSelectedMathAssignmentId,
  clearStudentSelectedProblemId,
} from '../../redux/slices/selectionSlice';
// Pass user prop to this component

const MainNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const dispatch = useDispatch();
  // const { user, userType } = useAppContext();
  const user = useSelector((state) => state.user.user);
  const userType = useSelector((state) => state.user.userType);
  console.log(user, userType);

  const handleHelpMe = () => {
    navigate('/help-me');
  };

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(clearUserType());
    dispatch(clearToken());

    dispatch(clearSelectedWeekId());
    dispatch(clearStudentSelectedProblemId());
    dispatch(clearSelectedProblemIds());
    dispatch(clearSelectedStudentIds());
    dispatch(clearProblems());
    dispatch(clearSelectedAssignmentIds());
    dispatch(clearSelectedChapterId());
    dispatch(clearStudentSelectedMathAssignmentId());
    dispatch(clearSelectedSubject());
    dispatch(clearMathAssignments());
    navigate('/login');
  };

  const studentItems = [
    {
      label: <Link to="/subjects">{user?.student_name}</Link>, // Display username, adjust according to your user object structure
      key: '0',
    },
    {
      label: `Roll No. ${user?.student_id}`, // Display user type, adjust as necessary
      key: '1',
    },
    {
      label: `Class ${user?.class}`, // Display user type, adjust as necessary
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <FontAwesomeIcon icon={faPowerOff} />{' '}
          <a onClick={handleLogout}>Log out</a>
        </>
      ), // Log out link
      key: '3',
    },
  ];

  const teacherItems = [
    {
      label: <Link to="/teacher-dashboard">{user?.teacher_name}</Link>, // Display username, adjust according to your user object structure
      key: '0',
    },
    // {
    //   label: `Roll No. ${user?.student_id}`, // Display user type, adjust as necessary
    //   key: '1',
    // },
    // {
    //   label: `Class ${user?.class}`, // Display user type, adjust as necessary
    //   key: '2',
    // },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <FontAwesomeIcon icon={faPowerOff} />{' '}
          <a onClick={handleLogout}>Log out</a>
        </>
      ), // Log out link
      key: '3',
    },
  ];

  return (
    <nav className="navbar">
      <input type="checkbox" id="check" />
      <label htmlFor="check" className="check-button">
        <i className="fa fa-bars"></i>
      </label>
      <label className="logo">
        <Link to="/" className="navbar-brand">
          <img className="logo-image" src={logoImage} alt="Logo" />
        </Link>
      </label>
      <ul>
        {user ? (
          // User is logged in
          <>
            <div className="admin-navbar">
              <div className="admin-nav-right">
                {userType === 'Student' && (
                  <Link to="/help-me" className="help-me-button">
                    Help Me!
                  </Link>
                )}
                <FontAwesomeIcon icon={faComment} />
                <FontAwesomeIcon icon={faBell} />

                {userType === 'Student' && (
                  <Dropdown
                    overlay={<Menu items={studentItems} />}
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <FontAwesomeIcon icon={faUser} /> <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                )}

                {userType === 'Teacher' && (
                  <Dropdown
                    overlay={<Menu items={teacherItems} />}
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <FontAwesomeIcon icon={faUser} /> <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                )}

                <div className="log-out-button" onClick={handleLogout}></div>
              </div>
            </div>
          </>
        ) : (
          // User is not logged in
          <>
            {!path.includes('/login/') && (
              <>
                <li>
                  <Link to="/contactUs" className="nav-link">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link className="book-a-demo" to="/login">
                    <div className="book-a-demo-button">
                      <div className="book-a-demo-frame">
                        <p className="book-a-demo-text">
                          <span className="book-a-demo-span">Log In </span>
                        </p>
                        <img
                          className="arrow-right"
                          src="https://c.animaapp.com/TEOYoSB0/img/arrow-right-1.png"
                          alt="Arrow Right"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              </>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default MainNavigation;
